import React, { useRef } from "react"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { FaLongArrowAltRight } from "react-icons/fa"

const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
  adaptiveHeight: true,
}

const FaqListSlider = ({ data }) => {
  var prevNext = useRef()
  const imageCounter = data => {
    let slider = []
    for (let i = 0; i < data.length; i = i + 4) {
      slider.push(
        <Row key={i.toString()}>
          {data[i] && (
            <Card key={`ak${i + i.toString()}`}>
              <FaqList to={data[i].item_link}>
                <FaqListTitle
                  dangerouslySetInnerHTML={{
                    __html: data[i].item_title,
                  }}
                />
                <ArrowWrapper>
                  <FaLongArrowAltRight size={18} />
                </ArrowWrapper>
              </FaqList>
            </Card>
          )}
          {data[i + 1] && (
            <Card key={`ak${i + 1 + i.toString()}`}>
              <FaqList to={data[i + 1].item_link}>
                <FaqListTitle
                  dangerouslySetInnerHTML={{
                    __html: data[i + 1].item_title,
                  }}
                />
                <ArrowWrapper>
                  <FaLongArrowAltRight size={18} />
                </ArrowWrapper>
              </FaqList>
            </Card>
          )}
          {data[i + 2] && (
            <Card key={`ak${i + 2 + i.toString()}`}>
              <FaqList to={data[i + 2].item_link}>
                <FaqListTitle
                  dangerouslySetInnerHTML={{
                    __html: data[i + 2].item_title,
                  }}
                />
                <ArrowWrapper>
                  <FaLongArrowAltRight size={18} />
                </ArrowWrapper>
              </FaqList>
            </Card>
          )}
          {data[i + 3] && (
            <Card key={`ak${i + 3 + i.toString()}`}>
              <FaqList to={data[i + 3].item_link}>
                <FaqListTitle
                  dangerouslySetInnerHTML={{
                    __html: data[i + 3].item_title,
                  }}
                />
                <ArrowWrapper>
                  <FaLongArrowAltRight size={18} />
                </ArrowWrapper>
              </FaqList>
            </Card>
          )}
        </Row>
      )
    }
    return slider
  }
  return typeof window !== "undefined" ? (
    <Carousel className="carousel">
      <DesktopSlider style={{ width: "100%" }}>
        <Flickity
          flickityRef={c => (prevNext = c)}
          options={flickityOptions}
          className={"carousel"}
        >
          {imageCounter(data).map(item => item)}
        </Flickity>
      </DesktopSlider>
    </Carousel>
  ) : null
}

const DesktopSlider = styled.div`
  width: 100%;
  display: block;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  object-fit: contain;
  flex-direction: column;
  padding: 0px 5px;
  min-height: 240px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Card = styled.div`
  display: flex;
  width: 100%;
`

const FaqList = styled(Link)`
  color: #262626;
  padding: 0px;
  margin-bottom: 15px;
  font-size: 14px;
  display: block;
  font-family: "Gotham Book";
  position: relative;
  padding-right: 15px;
  width: 100%;

  :hover {
    color: #777;
  }
`

const FaqListTitle = styled.span`
  color: #262626;
  font-size: 14px;
  font-family: "Gotham Book";
  position: relative;
`

const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translate(0%, -50%);
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  height: 100%;

  .carousel {
    width: 100%;
    height: 100%;
  }

  .flickity-viewport {
    width: 100%;
    height: 100%;
  }

  .flickity-slider {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 20px;
    height: 20px;
    border: 2px solid #111;
    bottom: -20px;
    top: auto;
    padding: 0px;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button.next {
    right: 30%;
  }
  .flickity-prev-next-button.previous {
    left: 30%;
  }

  .flickity-page-dots {
    bottom: -7px;
    max-width: 65px;
    margin: 0px auto;
    left: 0;
    right: 0;
  }
  .flickity-page-dots .dot {
    margin: 0 3px;
  }
`

export default FaqListSlider
